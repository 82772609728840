import React from "react";
import styled from "styled-components";

// regular 400
// bold 700

const Title = styled.div`
  font-family: Caveat;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  @media screen and (max-width: 500px) {
    font-size: 36px;
  }
`;

const Ttl = ({ children }) => <Title>{children}</Title>;

const Text1 = styled.div`
  font-size: 14px;
  color: ${(props) => props.color};
  line-height: 21px;
  letter-spacing: -0.25px;
  font-weight: ${(props) => props.fontWeight};
  text-decoration: ${(props) => (props.underline ? "underline" : "default")};
  @media screen and (max-width: 500px) {
    font-size: 15px;
    line-height: 24px;
  }
`;

const Txt1 = ({ children, fontWeight = 400, color, underline }) => (
  <Text1 fontWeight={fontWeight} color={color} underline={underline}>
    {children}
  </Text1>
);

const Text2 = styled.div`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.25px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  text-decoration: ${(props) => (props.underline ? "underline" : "default")};
  @media screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const Txt2 = ({ children, fontWeight = 400, color, underline }) => (
  <Text2 fontWeight={fontWeight} color={color} underline={underline}>
    {children}
  </Text2>
);

const Text3 = styled.div`
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -0.25px;
  font-weight: ${(props) => props.fontWeight};
`;

const Txt3 = ({ children, fontWeight = 400 }) => (
  <Text3 fontWeight={fontWeight}>{children}</Text3>
);

export { Ttl, Txt1, Txt2, Txt3 };
