import React, { useEffect } from "react";
import styled from "styled-components";
import { Txt1 } from "./Typo";
import { useNavigate } from "react-router-dom";

const Box = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
`;

const Crashed = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <Box>
      <Txt1 fontWeight={500}>Not Found</Txt1>
    </Box>
  );
};

export default Crashed;
