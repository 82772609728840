import instagram from "../Images/Icons/instagram.svg";
import blog from "../Images/Icons/blog.svg";

const ContactInfo = {
  channels: [
    { channel: instagram, url: "https://www.instagram.com/hottssun/" },
    {
      channel: blog,
      url: "https://blog.naver.com/hottssun",
      murl: "https://m.blog.naver.com/hottssun",
    },
  ],
  name: "Hasun Kang",
  year: 2023,
};

export default ContactInfo;
