import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Txt1 } from "../../Components/Typo";
import MagazineView from "../../Components/MagazineView";
import { Pg } from "../../Components/Utilities";
import { useOutletContext } from "react-router-dom";
import getContents from "../../Func/getContents";

const Box = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProfileImg = styled.img`
  object-fit: contain;
  object-position: top;
  width: 35%;
  margin-right: 5%;
  margin-top: 98px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 40px;
    margin-right: 0px;
    width: 312px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const AboutHs = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  margin-top: 313px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
    width: 100%;
  }
`;

const Cv = styled.a`
  color: #ff0000;
`;

const Bio = ({ path }) => {
  const context = useOutletContext();
  const { base } = context;
  const [viewContents, setViewContents] = useState([]);
  useEffect(() => {
    getContents(path, setViewContents);
  }, []);
  return (
    <>
      {viewContents === [] ? null : (
        <MagazineView>
          <Box>
            <ProfileImg src={`${base}/bio/profile.jpg`}></ProfileImg>
            <AboutHs className="disableDrag">
              <Pg margin="0px 0px 24px 0px">
                <Txt1 fontWeight={700}>Hasun Kang</Txt1>
              </Pg>
              <Pg margin="0px 0px 24px 0px">
                <Txt1>{viewContents[0]}</Txt1>
              </Pg>
              <Pg margin="0px 0px 60px 0px">
                <Txt1>{viewContents[1]}</Txt1>
              </Pg>
              {/* <Cv href={`${base}/about/hasunkang_cv.pdf`} target="_blank">
                Click to download CV
              </Cv> */}
            </AboutHs>
          </Box>
        </MagazineView>
      )}
    </>
  );
};

export default Bio;
