import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Txt1, Txt2 } from "./Typo";
import thumbnailsIcon from "../Images/Icons/thumbnails.svg";
import { isMobile } from "react-device-detect";

const Box = styled.div`
  width: calc(100% - 220px);
  height: ${(props) => `${props.vh}px`};
  /* min-height: 100vh;
  min-height: -webkit-fill-available; */
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 500;
  background-color: rgba(255, 255, 255, 1);
  padding: 60px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: ${(props) => `${props.vh - 85}px`};
    /* min-height: calc(100vh - 85px);
    min-height: -webkit-fill-available; */
    top: 85px;
    display: flex;
    padding: 40px 50px 50px;
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    height: ${(props) => `${props.vh - 60}px`};
    /* min-height: calc(100vh - 60px);
    min-height: -webkit-fill-available; */
    top: 60px;
    padding: 20px 20px 20px;
    overflow-y: scroll;
  }
`;

const Art = styled.img`
  height: 100%;
  width: calc(100% - 180px);
  object-fit: contain;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: calc(100% - 90px);
  }
`;

const UtilBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const UtilBoxM = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
`;

const Top = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

const Middle = styled.div`
  display: flex;
`;

const Count = styled.div`
  margin-right: 10px;
  opacity: 0.4;
`;

const PN = styled.div`
  margin: ${(props) => props.margin};
  cursor: pointer;
  &:hover {
    opacity: ${(props) => (props.isMobile ? 1 : 0.4)};
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 160px);
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const RightTop = styled.div`
  opacity: 0.4;
  cursor: pointer;
  text-align: right;
`;

const RightBottom = styled.div`
  margin-top: 4px;
  display: flex;
`;

const ImageViewer = ({
  currentIndex,
  closeImageViewer,
  list,
  clickPrev,
  clickNext,
  base,
  path,
}) => {
  const item = list[currentIndex];
  const [vh, setVerticalHeight] = useState(window.innerHeight);
  const setVh = () => {
    setVerticalHeight(window.innerHeight);
  };
  // console.log(vh);
  useEffect(() => {
    const timer = setInterval(() => {
      window.addEventListener("resize", setVh);
    }, 0);
    return () => {
      clearInterval(timer);
      window.removeEventListener("resize", setVh);
    };
  }, [vh]);
  return (
    <Box className="disableDrag" vh={vh}>
      <Art src={`${base}/${path}/${list.length - currentIndex}.jpg`}></Art>
      <UtilBox>
        <Top src={thumbnailsIcon} onClick={closeImageViewer}></Top>
        <Middle>
          <Count>
            <Txt2>
              ({currentIndex + 1} of {list.length})
            </Txt2>
          </Count>
          <PN
            margin="0px 5px 0px 0px"
            onClick={() => clickPrev(currentIndex, list)}
            isMobile={isMobile}
          >
            <Txt2>PREV</Txt2>
          </PN>
          <Txt2>/</Txt2>
          <PN
            margin="0px 0px 0px 5px"
            onClick={() => clickNext(currentIndex, list)}
            isMobile={isMobile}
          >
            <Txt2>NEXT</Txt2>
          </PN>
        </Middle>
        <Bottom>
          <Txt1 fontWeight={700}>{item.title}</Txt1>
          <Txt1>
            {item.material ? `${item.material},` : null}{" "}
            {item.size ? `${item.size}, ` : null}
            {item.date ? item.date : null}
          </Txt1>
        </Bottom>
      </UtilBox>
      <UtilBoxM>
        <Left>
          <Txt1 fontWeight={700}>{item.title}</Txt1>
          <Txt1>
            {item.material ? `${item.material},` : null}{" "}
            {item.size ? `${item.size}, ` : null}
            {item.date ? item.date : null}
          </Txt1>
        </Left>
        <Right>
          <RightTop onClick={closeImageViewer}>
            <Txt2 underline={true}>BACK TO THUMBNAILS</Txt2>
          </RightTop>
          <RightBottom>
            <Count>
              <Txt2>
                ({currentIndex + 1} of {list.length})
              </Txt2>
            </Count>
            <PN
              margin="0px 5px 0px 0px"
              onClick={() => clickPrev(currentIndex, list)}
            >
              <Txt2>PREV</Txt2>
            </PN>
            <Txt2>/</Txt2>
            <PN
              margin="0px 0px 0px 5px"
              onClick={() => clickNext(currentIndex, list)}
            >
              <Txt2>NEXT</Txt2>
            </PN>
          </RightBottom>
        </Right>
      </UtilBoxM>
    </Box>
  );
};

export default ImageViewer;
