import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import default_vertical from "../Images/Icons/default_vertical.png";

const Item = styled.div`
  padding-top: 133%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${(props) =>
    `rgba(255,255,255,1) url(${props.src}) no-repeat center center`};
  background-size: cover;
  cursor: pointer;
`;

const GridItem = ({ index, src, func }) => {
  const [isLoading, setIsLoading] = useState(false);
  const imgRef = useRef(null);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(intersectionOberserver);
    imgRef.current && observer.current.observe(imgRef.current);
  }, []);

  const intersectionOberserver = (entries, io) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 관찰되고 있는 entry가 보여지게 된다면
        io.unobserve(entry.target); // 관찰 종료
        setIsLoading(true); // 로딩 체크
      }
    });
  };

  return (
    <Item
      ref={imgRef}
      src={isLoading ? src : default_vertical}
      onClick={() => func(index)}
    ></Item>
  );
};

export default GridItem;
