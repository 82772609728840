import React, { useState } from "react";
import styled from "styled-components";
import { Txt1, Txt2 } from "../../Components/Typo";
import MagazineView from "../../Components/MagazineView";
import { Pg } from "../../Components/Utilities";
import { useEffect } from "react";
import getContents from "../../Func/getContents";
import Parser from "html-react-parser";

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StageBox = styled.div`
  ::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    height: 149.25px;
    content: "";
    position: fixed;
    top: 0px;
  }
  position: fixed;
  top: 149.25px;
  left: 280px;
  width: 100%;
  height: 32px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    left: 0px;
    padding: 0px 50px 0px;
    top: 85px;
    ::before {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    top: 60px;
    left: 0px;
    padding: 0px 20px 0px;
  }
`;

const StageBtn = styled.div`
  margin-right: 16px;
  opacity: ${(props) => (props.selected ? "1" : "0.4")};
  position: relative;
  cursor: pointer;
  ::after {
    opacity: ${(props) => (props.selected ? "1" : "0")};
    content: "";
    width: 100%;
    height: 2px;
    background-color: #ff0000;
    position: absolute;
    bottom: -4px;
    left: 0px;
  }
  &:hover {
    opacity: ${(props) => (props.selected ? 1 : 0.6)};
  }
`;

const ContentsBox = styled.div`
  width: 100%;
  margin-top: 161.25px;
  @media screen and (max-width: 768px) {
    margin-top: 72px;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: none;
  }
`;

const Border = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Texts = ({ path }) => {
  const [category, setCategory] = useState("exhibition");
  const [viewContents, setViewContents] = useState([]);
  useEffect(() => {
    getContents(path, setViewContents);
  }, []);
  return (
    <>
      {viewContents === [] ? null : (
        <MagazineView>
          <Box className="disableDrag">
            <StageBox>
              <StageBtn
                onClick={() => setCategory("exhibition")}
                selected={category === "exhibition" ? true : false}
                category={category}
              >
                <Txt1 fontWeight={category === "exhibition" ? 700 : 400}>
                  Exhibition
                </Txt1>
              </StageBtn>
              <StageBtn
                onClick={() => setCategory("diary")}
                selected={category === "diary" ? true : false}
                category={category}
              >
                <Txt1 fontWeight={category === "diary" ? 700 : 400}>Diary</Txt1>
              </StageBtn>
            </StageBox>
            <ContentsBox>
              {viewContents.map((item, index) =>
                category === item.category ? (
                  <TextBox key={index}>
                    <Pg margin="0px 0px 6px 0px">
                      <Txt1 fontWeight={700}>{item.title}</Txt1>
                    </Pg>
                    <Pg margin="0px 0px 24px 0px">
                      <Txt2 color="rgba(153,153,153,1)">{item.date}</Txt2>
                    </Pg>
                    <Pg margin="0px 0px 40px 0px">
                      <Txt1>{Parser(item.content)}</Txt1>
                    </Pg>
                    {/* {viewContents.length - 1 === index ? null : (
                      <Border></Border>
                    )} */}
                  </TextBox>
                ) : null
              )}
            </ContentsBox>
          </Box>
        </MagazineView>
      )}
    </>
  );
};

export default Texts;
