import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Menu from "./Menu";
import { LinkBtn } from "./Utilities";
import DrawerMenu from "./DrawerMenu";
import Drawer from "./Drawer";
import { isMobile } from "react-device-detect";

const Box = styled.div`
  position: fixed;
  top: 60px;
  left: 60px;
  width: 160px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  z-index: 2000;
  @media screen and (max-width: 768px) {
    padding: 0px 50px 0px;
    left: 0px;
    top: 0px;
    height: 85px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 500px) {
    top: ${(props) =>
      props.path === "" ? (props.scrollHeight > 386 ? "0px" : "-60px") : "0px"};
    height: 60px;
    padding: 0px 20px 0px;
  }
`;

const Logo = styled.div`
  width: 18px;
  height: 18px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 50%;
  &:hover {
    background-color: ${(props) =>
      props.isMobile ? "rgba(0,0,0,1)" : "#ff0000"};
  }
`;

const Header = ({ path, scrollHeight }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box className="disableDrag" scrollHeight={scrollHeight} path={path}>
        <LinkBtn path="" margin="0px auto 0px 0px" logo={true}>
          <Logo isMobile={isMobile}></Logo>
        </LinkBtn>
        <Menu path={path}></Menu>
        <DrawerMenu setOpen={setOpen}></DrawerMenu>
      </Box>
      <Drawer path={path} open={open} setOpen={setOpen}></Drawer>
    </>
  );
};

export default Header;
