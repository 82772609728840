import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Txt1 } from "./Typo";

const Box = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  &:hover {
    opacity: ${(props) => (props.isMobile ? 1 : 0.4)};
    cursor: pointer;
  }
`;

const DrawerMenu = ({ setOpen }) => {
  return (
    <Box isMobile={isMobile} onClick={() => setOpen(true)}>
      <Txt1 underline={true}>MENU</Txt1>
    </Box>
  );
};

export default DrawerMenu;
