import React, { useEffect } from "react";
import styled from "styled-components";
import close from "../Images/Icons/close.svg";
import redlines from "../Images/Icons/redlines.svg";
import { Txt2 } from "./Typo";
import MenuList from "../Data/MenuList";
import { LinkBtn } from "./Utilities";
import ContactInfo from "../Data/ContactInfo";
import { isMobile } from "react-device-detect";

const Box = styled.div`
  position: fixed;
  z-index: 3000;
  top: 0px;
  /* height: 100vh;
  height: -webkit-fill-available;
  height: fill-available; */
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: none;
  padding-top: 107px;
  background-color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    right: 0px;
  }
`;

const CloseIcon = styled.img`
  position: fixed;
  top: 40px;
  right: 20px;
  height: 48px;
  cursor: pointer;
`;

const ArtBox = styled.div`
  width: 100%;
  height: 65px;
  position: relative;
  display: flex;
  justify-content: center;
`;

const RedLines = styled.img`
  margin-top: 29px;
  height: 19px;
  width: 768px;
  /* object-fit: cover; */
  z-index: 5000;
`;

const Circle = styled.div`
  border-radius: 65px;
  height: 65px;
  width: 65px;
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  z-index: 4000;
  animation: fadeInUp 1s;
`;

const MenuBox = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 222px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Menu = styled.span`
  font-size: 17px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => (props.current ? "#ff0000" : "default")};
  text-decoration: ${(props) =>
    props.current ? "underline #ff0000 " : " none"};
`;

const ChannelBox = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const RedDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ff0000;
`;

const Channel = styled.a`
  font-weight: 400;
  letter-spacing: -0.25px;
  font-size: 17px;
  margin: ${(props) => (props.insta ? "0px 16px 0px 0px" : "0px 0px 0px 16px")};
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

const CopyRight = styled.div`
  height: 20px;
  width: 100%;
  margin-bottom: 30px;
  opacity: 0.4;
  display: flex;
  justify-content: center;
`;

const Drawer = ({ path, open, setOpen }) => {
  useEffect(() => {
    setOpen(false);
  }, [path]);
  return open ? (
    <Box className="disableDrag">
      <CloseIcon src={close} onClick={() => setOpen(false)}></CloseIcon>
      <ArtBox>
        <RedLines src={redlines}></RedLines>
        <Circle></Circle>
      </ArtBox>
      <MenuBox>
        {MenuList.map((menu, index) => (
          <LinkBtn key={index} path={menu.path} margin="0px 0px 30px 0px">
            <Menu
              key={index}
              fontWeight={menu.fontWeight}
              current={path === menu.path ? true : false}
            >
              {menu.title}
            </Menu>
          </LinkBtn>
        ))}
        <ChannelBox>
          <Channel
            insta={true}
            href={ContactInfo.channels[0].url}
            target="_blank"
          >
            Insta
          </Channel>
          <RedDot></RedDot>
          <Channel
            insta={false}
            href={
              isMobile
                ? ContactInfo.channels[1].murl
                : ContactInfo.channels[1].url
            }
            target="_blank"
          >
            Blog
          </Channel>
        </ChannelBox>
      </MenuBox>
      <CopyRight>
        <Txt2>Hasun Kang,︎ 2023 © All rights reserved</Txt2>
      </CopyRight>
    </Box>
  ) : null;
};

export default Drawer;
