import { createHashRouter } from "react-router-dom";
import Root from "../Root";
import View from "../Screens/View";
import NotFound from "../Screens/NotFound";
import Crashed from "./Crashed";

const router = createHashRouter([
  {
    path: "/",
    element: <Root></Root>,
    children: [
      {
        path: "",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
      {
        path: "paintings",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
      {
        path: "drawings",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
      {
        path: "others",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
      {
        path: "graphics",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
      {
        path: "texts",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
      {
        path: "bio",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
      {
        path: "contact",
        element: <View></View>,
        errorElement: <Crashed></Crashed>,
      },
    ],
    errorElement: <NotFound></NotFound>,
  },
]);

export default router;
