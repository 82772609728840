import React, { useState, useCallback } from "react";
import styled from "styled-components";
import ImageViewer from "./ImageViewer";
import { useOutletContext } from "react-router-dom";
import GridItem from "./GridItem";

const Box = styled.div`
  width: 100%;
  position: relative;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: ${(props) => (props.isViewerOpen ? "none" : "grid")};
  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 24px;
  }
`;

const GridView = ({ viewContents }) => {
  const context = useOutletContext();
  const { base, path } = context;
  // const DPG = path.substr(0, 1).toUpperCase();
  const list = [...viewContents];
  // console.log(list);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const clickNext = (currentImage, list) => {
    if (currentImage === list.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage((prev) => prev + 1);
    }
  };

  const clickPrev = (currentImage, list) => {
    if (currentImage === 0) {
      setCurrentImage(list.length - 1);
    } else {
      setCurrentImage((prev) => prev - 1);
    }
  };

  return (
    <>
      <Box isViewerOpen={isViewerOpen}>
        {list.map((item, index) => (
          <GridItem
            key={index}
            index={index}
            src={`${base}/${path}/${list.length - index}.jpg`}
            func={openImageViewer}
          ></GridItem>
        ))}
      </Box>
      {isViewerOpen && (
        <ImageViewer
          currentIndex={currentImage}
          closeImageViewer={closeImageViewer}
          clickNext={clickNext}
          clickPrev={clickPrev}
          list={list}
          base={base}
          path={path}
        ></ImageViewer>
      )}
    </>
  );
};

export default GridView;
