import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Components/Header";
import GlobalStyles from "./Components/GlobalStyles";
import Container from "./Components/Container";

function Root() {
  const base = "https://hasunkang.github.io/portfoliodb";
  const { pathname } = useLocation();
  const path = pathname.substr(1);
  const [scrollHeight, setScrollHeight] = useState(0);
  const scrollFunc = () => {
    setScrollHeight(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("contextmenu", (ev) => {
      ev.preventDefault();
    });
    const timer = setInterval(() => {
      window.addEventListener("scroll", scrollFunc);
    }, 100);
    return () => {
      clearInterval(timer);
      window.removeEventListener("scroll", scrollFunc);
    };
  }, []);
  return (
    <>
      <Container>
        <Header path={path} scrollHeight={scrollHeight}></Header>
        <Outlet
          context={{
            path: path,
            scrollHeight: scrollHeight,
            base: base,
          }}
        ></Outlet>
      </Container>
      <GlobalStyles></GlobalStyles>
    </>
  );
}

export default Root;
