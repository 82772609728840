import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Txt2 } from "./Typo";
import emailjs from "@emailjs/browser";

const Box = styled.form`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

const FormBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const FormTitleBox = styled.div`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  height: 19px;
`;

const FormTitleDot = styled.div`
  border-radius: 50%;
  background-color: #ff0000;
  width: 4px;
  height: 4px;
  margin-right: 6px;
`;

const FormInput = styled.input`
  width: 100%;
  background-color: #f8f8f8;
  height: 36px;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 12px;
  cursor: pointer;
  &:hover {
    background-color: rgba(236, 236, 236, 1);
  }
  &:focus {
    box-shadow: 0 0 0 1px #000 inset;
  }
`;

const FormArea = styled.textarea`
  font-family: Inter, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  width: 100%;
  background-color: #f8f8f8;
  height: 100px;
  border-radius: 4px;
  font-size: 12px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: rgba(236, 236, 236, 1);
  }
  &:focus {
    box-shadow: 0 0 0 1px #000 inset;
  }
`;

const SendBox = styled.div`
  display: flex;
  width: ${(props) => (props.sending ? "82px" : "54px")};
  height: 28px;
  border-radius: 4px;
  background-color: ${(props) => (props.filled ? "#000000" : "#cccccc")};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 8px;
`;

const Send = styled.input`
  font-size: 10px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
`;

const TextInput = ({ changeValue, inputValue, initValue }) => {
  const { name, email, message } = inputValue;
  const [sending, setSending] = useState(false);
  const [filled, setFilled] = useState(false);
  useEffect(() => {
    if (name === "" || email === "" || message === "") {
      setFilled(false);
    } else {
      setFilled(true);
    }
  }, [inputValue]);
  const form = useRef();
  const onTextChange = (event, id) => {
    const value = event.target.value;
    changeValue(id, value);
  };
  const sendMail = (e) => {
    e.preventDefault();
    if (filled && !sending) {
      setSending(true);
      emailjs
        .sendForm(
          "service_6fpxzgh",
          "template_8v6g5je",
          form.current,
          "sswvpoQ8FRlCCF9mp"
        )
        .then(
          (result) => {
            initValue();
            setSending(false);
            alert("Message has been sent!");
          },
          (error) => {
            alert("Failed to send. Please try again.");
            // console.log(error.text);
          }
        );
    } else {
      return;
    }
  };
  return (
    <Box ref={form} onSubmit={sendMail}>
      <FormBox>
        <FormTitleBox>
          <FormTitleDot></FormTitleDot>
          <Txt2>Name</Txt2>
        </FormTitleBox>
        <FormInput
          type="text"
          onChange={(event) => onTextChange(event, "name")}
          value={name}
          name="name"
        ></FormInput>
      </FormBox>
      <FormBox>
        <FormTitleBox>
          <FormTitleDot></FormTitleDot>
          <Txt2>Email</Txt2>
        </FormTitleBox>
        <FormInput
          type="text"
          onChange={(event) => onTextChange(event, "email")}
          value={email}
          name="email"
        ></FormInput>
      </FormBox>
      <FormBox>
        <FormTitleBox>
          <FormTitleDot></FormTitleDot>
          <Txt2>Message</Txt2>
        </FormTitleBox>
        <FormArea
          onChange={(event) => onTextChange(event, "message")}
          value={message}
          name="message"
        ></FormArea>
      </FormBox>
      <SendBox filled={filled} sending={sending}>
        <Send type="submit" value={sending ? "SENDING..." : "SEND"}></Send>
      </SendBox>
    </Box>
  );
};

export default TextInput;
