import React, { useState } from "react";
import styled from "styled-components";
import img1 from "../../Images/Introducing/1.jpg";
import img1m from "../../Images/Introducing/1m.jpg";
import img2_1 from "../../Images/Introducing/2_1.jpg";
import img2_2 from "../../Images/Introducing/2_2.jpg";
import img2_1m from "../../Images/Introducing/2_1m.jpg";
import img2_2m from "../../Images/Introducing/2_2m.jpg";
import img3_1 from "../../Images/Introducing/3_1.jpg";
import img3_2 from "../../Images/Introducing/3_2.jpg";
import img3_3 from "../../Images/Introducing/3_3.jpg";
import img3_4 from "../../Images/Introducing/3_4.jpg";
import { Txt1 } from "../../Components/Typo";
import { Pg, Grd } from "../../Components/Utilities";
import MagazineView from "../../Components/MagazineView";
import { useOutletContext } from "react-router-dom";
import { isMobile } from "react-device-detect";

const FullWidth = styled.div`
  @media screen and (max-width: 500px) {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    flex-direction: column;
    width: 100%;
  }
`;

const HeaderImg = styled.img`
  width: 100%;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const MobileHeader = styled.div`
  height: 580px;
  width: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 1);
  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
`;

const MobileHeaderImg = styled.img`
  position: fixed;
  height: ${(props) => `${580 - props.scrollHeight}px`};
  object-fit: cover;
  width: 100%;
`;

const StageBox = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1000;
  margin-bottom: 16px;
`;

const StageBtn = styled.div`
  z-index: 1000;
  margin-right: 16px;
  opacity: ${(props) => (props.selected ? "1" : "0.4")};
  position: relative;
  cursor: pointer;
  ::after {
    opacity: ${(props) => (props.selected ? "1" : "0")};
    content: "";
    width: 100%;
    height: 2px;
    background-color: #000000;
    position: absolute;
    bottom: -4px;
    left: 0px;
  }
  &:hover {
    opacity: ${(props) => (props.selected ? 1 : 0.6)};
  }
`;

const Background = styled.div`
  z-index: 1000;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
`;

const Contents = styled.div`
  width: 100%;
  @media screen and (max-width: 500px) {
    background-color: rgba(255, 255, 255, 1);
    padding: 0px 20px 30px;
  }
`;

const Intro = styled.div`
  z-index: 1000;
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Introducing = () => {
  const [language, setLanguage] = useState("eng");
  const context = useOutletContext();
  const { scrollHeight } = context;
  return (
    <MagazineView>
      <FullWidth>
        <HeaderImg src={img1}></HeaderImg>
        <MobileHeader>
          <MobileHeaderImg
            src={img1m}
            scrollHeight={scrollHeight}
          ></MobileHeaderImg>
        </MobileHeader>
        <Background>
          <Contents className="disableDrag">
            <Intro>
              <Txt1 fontWeight={700} color={"#ff0000"}>
                Why I Paint
              </Txt1>
              <Txt1 fontWeight={400} color={"#ff0000"}>
                Introduction
              </Txt1>
            </Intro>
            <StageBox>
              <StageBtn
                onClick={() => setLanguage("eng")}
                selected={language === "eng" ? true : false}
                language={language}
              >
                <Txt1 fontWeight={language === "eng" ? 700 : 400}>Eng</Txt1>
              </StageBtn>
              <StageBtn
                onClick={() => setLanguage("kor")}
                selected={language === "kor" ? true : false}
                language={language}
              >
                <Txt1 fontWeight={language === "kor" ? 700 : 400}>Kor</Txt1>
              </StageBtn>
            </StageBox>
            <Pg margin="0px 0px 0px 0px">
              <Txt1>
                {language === "eng" ? (
                  <>
                    As human beings, we each carry within us an infinite world,
                    a universe of our own. But the deafening noise of the world
                    can shroud this fact, obscuring the brilliance that lies
                    within us. We grow accustomed to the evaluations of others
                    and we become trapped in the cages of our own insecurities.
                    For me, painting is a journey of self-discovery, a quest to
                    unveil the shining pearl buried deep within. It is a path
                    that leads to a profound understanding of the value of
                    myself, as a human being, and a recognition of the worth of
                    those who share my image.<br></br>
                    <br></br>My paintings convey a message of love that
                    resonates through every stroke of the brush based on this
                    understanding. Yet this message of love is not born without
                    struggle, for the path to enlightenment is fraught with the
                    shadows of depression, loneliness, and countless mistakes.
                    But even in the darkest moments, I hold fast to the hope
                    that I will find myself at the end of this journey, and that
                    my pain will serve as a beacon of empathy and comfort for
                    others.<br></br>
                    <br></br>I depict humans in the form of a circle, a single
                    point in the vast expanse of the universe. But this does not
                    mean that our existence is helpless, for within the circle
                    lies the boundless potential of our being. It is a symbol of
                    our longing for the original purpose of our existence, a
                    desire to become round beings in a square and sharp world.
                  </>
                ) : (
                  <>
                    우리 안에 있는 우주는 세상의 소음으로 흐릿해지곤 한다.
                    무엇이 나의 의무인지 나보다 더 잘 안다고 말하는 사람들의
                    말에 각자의 우주는 잠식되어 가고 나의 색은 옅어져 간다.
                    우리는 서로에게 평가의 잣대를 들이미는 것에, 누군가를
                    동경하고 누군가에게 동경받는 것에 중독되어 간다. 불안이라는
                    감옥은 그렇게 공고해져간다.<br></br>
                    <br></br>그림은 내 안의 우주를 찾아가는 여정이다. 결국 이
                    세상에서 살아가야 하기에, 이왕이면 잘 살고 싶기에 이 여정은
                    나에게 생존의 방법이기도 하다. 한 명의 인간으로서의 ‘나’의
                    가치를 깨닫는 것은 결국 나와 같은 형상을 하고 있는 내 옆의
                    사람들에 대한 가치의 인정으로 이어진다. 잘 알지 못했었는데,
                    당신의 가면과 가시는 나처럼 아픔을 견디어 내느라 만들어진
                    것임을, 당신도 나와 같이 참 아름다운 사람임을 이해할 수 있게
                    된다.<br></br>
                    <br></br>이 깨달음은 결국 내 그림이 사랑의 메시지를 품게
                    만든다. 이는 아름다워 보이나 투쟁 없이 만들어지지 않는다.
                    우울, 고독, 방황과 수많은 실수들의 그림자로 가득 차 있는
                    어두운 과정의 끝에서 나는 나를 찾을 수 있을 것이고, 그려낼
                    것이고, 보는 이들에게 공감과 위로를 전하게 될 것이라는
                    희망을 붙들고 있다.<br></br>
                    <br></br>나는 그림에서 인간을 하나의 원으로 표현한다. 본래
                    모두가 평등했던 우주 속 하나의 점으로 시작된 태초의 기준을
                    동경하는 나의 표현법임과 동시에, 네모지고 날카로운 것들로
                    가득한 이 세상에서 우리만은 서로에게 둥근 존재가 되길 바라는
                    마음이기도 하다.
                  </>
                )}
              </Txt1>
            </Pg>
          </Contents>
          <Pg margin={isMobile ? "50px 0px 0px 0px" : "80px 0px 0px 0px"}>
            <Grd
              pgtc="3fr 1fr"
              mgtc="1fr"
              imgArray={[
                [img2_1, "30%", img2_1m, "61.069%"],
                [img2_2, "100%", img2_2m, "48.346%"],
              ]}
            ></Grd>
          </Pg>
          <Contents>
            <Pg margin="20px 0px 0px 0px">
              <Txt1>
                {language === "eng" ? (
                  <>
                    Breaking free from the iron bars that constrain me is always
                    difficult, but it allows me to discover my shining self,
                    like a pearl.
                  </>
                ) : (
                  <>
                    무엇이 나의 철창인지 분별하고 이것에서 벗어난 나의 모습을
                    찾아야 한다.
                  </>
                )}
              </Txt1>
            </Pg>
            <Pg margin="70px 0px 0px 0px">
              <Grd
                pgtc="repeat(4, 1fr)"
                mgtc="repeat(2, 1fr)"
                imgArray={[
                  [img3_1, "100%", img3_1, "100%"],
                  [img3_2, "100%", img3_2, "100%"],
                  [img3_3, "100%", img3_3, "100%"],
                  [img3_4, "100%", img3_4, "100%"],
                ]}
              ></Grd>
            </Pg>
            <Pg margin="20px 0px 0px 0px">
              <Txt1>
                {language === "eng" ? (
                  <>
                    I strive to break free from the shackles that bind me, to
                    pursue my passions with unrelenting determination. For it is
                    only through the act of creating, through the act of
                    writing, watching, and feeling, that I can share my message
                    of love and inspiration with the world.
                  </>
                ) : (
                  <>
                    그리고, 쓰고, 연구하고, 감명받음이 내 메시지에 힘을 싣는
                    순간들이 되길.
                  </>
                )}
              </Txt1>
            </Pg>
          </Contents>
        </Background>
      </FullWidth>
    </MagazineView>
  );
};

export default Introducing;
