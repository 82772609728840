import React from "react";
import styled from "styled-components";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
`;

const Magazine = ({ children }) => {
  return <Box>{children}</Box>;
};

export default Magazine;
