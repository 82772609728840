const MenuList = [
  {
    title: "Why I Paint",
    fontWeight: 700,
    path: "",
  },
  {
    title: "Paintings",
    fontWeight: 700,
    path: "paintings",
  },
  {
    title: "Drawings",
    fontWeight: 700,
    path: "drawings",
  },
  {
    title: "Graphics",
    fontWeight: 700,
    path: "graphics",
  },
  {
    title: "Others",
    fontWeight: 700,
    path: "others",
  },
  {
    title: "Texts",
    fontWeight: 700,
    path: "texts",
  },
  {
    title: "Bio",
    fontWeight: 400,
    path: "bio",
  },
  {
    title: "Contact",
    fontWeight: 400,
    path: "contact",
  },
];

export default MenuList;
