import React from "react";
import styled from "styled-components";

const Box = styled.div`
  padding-left: 220px;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 0px;
  }
`;

const Contents = ({ children }) => {
  return <Box>{children}</Box>;
};

export default Contents;
