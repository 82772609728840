import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Txt1, Ttl } from "../../Components/Typo";
import MagazineView from "../../Components/MagazineView";
import { Pg } from "../../Components/Utilities";
import TextInput from "../../Components/TextInput";
import getContents from "../../Func/getContents";

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 98px;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding-left: 50px;
  }
  @media screen and (max-width: 500px) {
    padding-top: 38px;
    padding-left: 0px;
  }
`;

const Contact = ({ path }) => {
  const [viewContents, setViewContents] = useState([]);
  useEffect(() => {
    getContents(path, setViewContents);
  }, []);
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    message: "",
  });
  const changeValue = (id, value) => {
    setInputValue({ ...inputValue, [id]: value });
  };
  const initValue = () => {
    setInputValue({ ...inputValue, name: "", email: "", message: "" });
  };
  return (
    <>
      {viewContents === [] ? null : (
        <MagazineView>
          <Box>
            <Pg margin="0px 0px 18px 0px">
              <Ttl>Hasun Kang</Ttl>
            </Pg>
            <Pg margin="0px 0px 14px 0px">
              <Txt1>
                Appreciate you stopping by.<br></br>
                Message me for inquiries about available works, exhibitions, or
                anything else.
              </Txt1>
            </Pg>
            <Pg margin="0px 0px 40px 0px">
              <Txt1 color="#ff0000">{viewContents[0]}</Txt1>
            </Pg>
            <TextInput
              inputValue={inputValue}
              changeValue={changeValue}
              initValue={initValue}
            ></TextInput>
          </Box>
        </MagazineView>
      )}
    </>
  );
};

export default Contact;
