import React from "react";
import { useOutletContext } from "react-router-dom";
import Contents from "../Components/Contents";
import Introducing from "./Menu/Introducing";
import Paintings from "./Menu/Paintings";
import Drawings from "./Menu/Drawings";
import Graphics from "./Menu/Graphics";
import Bio from "./Menu/Bio";
import Contact from "./Menu/Contact";
import Texts from "./Menu/Texts";
import Others from "./Menu/Others";

const View = () => {
  const context = useOutletContext();
  const getScreen = () => {
    const path = context.path === "" ? "introduction" : context.path;
    const screens = {
      introduction: <Introducing></Introducing>,
      paintings: <Paintings path={path}></Paintings>,
      drawings: <Drawings path={path}></Drawings>,
      graphics: <Graphics path={path}></Graphics>,
      others: <Others path={path}></Others>,
      texts: <Texts path={path}></Texts>,
      bio: <Bio path={path}></Bio>,
      contact: <Contact path={path}></Contact>,
      // painting: <Drawing viewContents={viewContents}></Drawing>,
      // Graphic: <Drawing viewContents={viewContents}></Drawing>,
    };
    return screens[path];
  };
  return <Contents>{getScreen()}</Contents>;
};

export default View;
