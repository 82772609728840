import React from "react";
import styled from "styled-components";
import { Txt3 } from "./Typo";
import { isMobile } from "react-device-detect";
import ContactInfo from "../Data/ContactInfo";

const Box = styled.div`
  margin-top: 74px;
  display: flex;
  flex-direction: column;
`;

const ChannelBox = styled.div`
  display: flex;
`;

const ChannelLink = styled.a`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  &:hover {
    opacity: ${(props) => (props.isMobile ? 1 : 0.4)};
  }
  :last-child {
    margin-right: 0px;
  }
`;

const Channel = styled.img`
  width: 100%;
`;

const CopyRight = styled.div`
  margin-top: 10px;
`;

const Contact = () => {
  return (
    <Box>
      <ChannelBox>
        {ContactInfo.channels.map((item, index) => (
          <ChannelLink
            key={index}
            href={item.url}
            target="_blank"
            isMobile={isMobile}
          >
            <Channel src={item.channel}></Channel>
          </ChannelLink>
        ))}
      </ChannelBox>
      <CopyRight>
        <Txt3 fontWeight={400}>
          {ContactInfo.name}, {ContactInfo.year}
          <p></p>© All rights reserved
        </Txt3>
      </CopyRight>
    </Box>
  );
};

export default Contact;
