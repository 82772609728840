import React from "react";
import styled from "styled-components";

const BigBox = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const SmallBox = styled.div`
  width: 100%;
  display: flex;
  padding: 60px 60px 160px;
  @media screen and (max-width: 768px) {
    padding: 85px 50px 40px;
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    padding: 60px 20px 30px;
  }
`;

const Container = ({ children }) => {
  return (
    <BigBox>
      <SmallBox>{children}</SmallBox>
    </BigBox>
  );
};

export default Container;
