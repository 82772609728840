import React, { useState, useEffect } from "react";
import GridView from "../../Components/GridView";
import getContents from "../../Func/getContents";

const Drawings = ({ path }) => {
  const [viewContents, setViewContents] = useState([]);
  useEffect(() => {
    getContents(path, setViewContents);
  }, []);
  return (
    <>
      {viewContents === [] ? null : (
        <GridView viewContents={viewContents}></GridView>
      )}
    </>
  );
};

export default Drawings;
