import React from "react";
import styled from "styled-components";
import { Txt2 } from "./Typo";
import MenuList from "../Data/MenuList";
import { LinkBtn } from "./Utilities";
import Contact from "./Contact";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 74px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Menu = ({ path }) => {
  return (
    <Box>
      {MenuList.map((menu, index) => (
        <LinkBtn key={index} path={menu.path} margin="6px auto 6px 0px">
          <Txt2
            key={index}
            fontWeight={menu.fontWeight}
            color={path === menu.path ? "#ff0000" : "default"}
          >
            {menu.title}
          </Txt2>
        </LinkBtn>
      ))}
      <Contact></Contact>
    </Box>
  );
};

export default Menu;
