const getContents = (path, func) => {
  func([]);
  const fetchUrl = `https://hasunkang.github.io/portfoliodb/${path}/contents.json`;
  fetch(fetchUrl)
    .then((res) => res.json())
    .then(async (results) => {
      func(results);
    })
    .catch((err) => {
      throw err;
    });
};

export default getContents;
