import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import React from "react";
import { isMobile } from "react-device-detect";

const LinkButton = styled.div`
  cursor: pointer;
  &:hover {
    opacity: ${(props) => (props.isMobile || props.logo ? 1 : 0.4)};
  }
  margin: ${(props) => props.margin};
`;

const LinkBtn = ({ children, path, margin, logo }) => {
  const navigate = useNavigate();
  const linkTo = (path) => {
    navigate(`/${path}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <LinkButton
      onClick={() => linkTo(path)}
      isMobile={isMobile}
      margin={margin}
      logo={logo}
    >
      {children}
    </LinkButton>
  );
};

const Paragraph = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.margin};
`;

const Pg = ({ children, margin }) => (
  <Paragraph margin={margin}>{children}</Paragraph>
);

const Grid = styled.div`
  z-index: 1000;
  width: 100%;
  display: grid;
  grid-gap: 46px;
  grid-template-columns: ${(props) => props.pgtc};
  @media screen and (max-width: 500px) {
    grid-template-columns: ${(props) => props.mgtc};
    grid-gap: 29px;
  }
`;

const GridItem = styled.div`
  width: 100%;
  padding-top: ${(props) => props.pdt};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    `rgba(255,255,255,1) url(${props.src}) no-repeat center center`};
  background-size: cover;
  @media screen and (max-width: 500px) {
    background: ${(props) =>
      `rgba(255,255,255,1) url(${props.msrc}) no-repeat center center`};
    padding-top: ${(props) => props.mpdt};
    background-size: cover;
  }
`;

const Grd = ({ pgtc, mgtc, imgArray }) => (
  <Grid pgtc={pgtc} mgtc={mgtc}>
    {imgArray.map((img, index) => (
      <GridItem
        key={index}
        src={img[0]}
        msrc={img[2]}
        pdt={img[1]}
        mpdt={img[3]}
      ></GridItem>
    ))}
  </Grid>
);

export { LinkBtn, Pg, Grd };
